<template>
  <pmis-content-box :loading="listBoxLoading">
    <template v-slot:title>
      하도급동의서
    </template>
    <template v-slot:title-left>
      <font style="color:#FF6666; font-weight: bold;">(더 많은 정보는 로그인 후 사용 바랍니다.)</font>
    </template>
    <template #title-right>
      <iui-button color="blue" v-show="isCanSign" @click="onSign">서명</iui-button>
    </template>

    <iui-container type="table" :theme="theme" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" v-if="isOzReport">
            <div style="width:100%; height:100%" v-if="isViewPdf">
              <iframe
                id="pdfViewer"
                name="pdfViewer"
                :src="pdfViewerConfig.viewerUrl"
                title="PDF Viewer"
                style="width: 100%; height: 100%; border: 0px solid red; overflow: hidden;"
              ></iframe>
            </div>
            <div style="width:100%; height:100%" v-else>
              <iframe
                :src="urlParam"
                id="ozFrame"
                name="ozFrame"
                style="width:100%; height:100%; border:0px solid red;"
                scrolling="no"
                title="Iframe Example"
              ></iframe>
            </div>
            
          </div>
          <div style="width:100%; height:100%" v-else>
            <pmis-content-box no-border-box>
              <template #title-right>
                <iui-button @click="($refs.pdf.length ? $refs.pdf[0] : $refs.pdf).print()">출력</iui-button>
                <iui-button @click="downloadPdf()">다운로드</iui-button>
              </template>
              <div style="height:calc(100vh - 130px); width:100%; overflow:scroll;">
                <pdf
                  ref="pdf"
                  v-for="i in numPages"
                  :key="i"
                  :src="pdfUrl"
                  :page="i"
                  @error="error"
                  @progress="loadedRatio = $event"
                  style="display:inline-block;width:calc(100% - 20px);margin:10px;border:1px solid #a0a0a0;box-shadow:5px 5px 10px #a0a0a0"
                />
              </div>
            </pmis-content-box>
          </div>
        </i-col>
      </i-row>
      <i-row v-show="isShowSign">
        <i-col-header>서명방법</i-col-header>
        <i-col>
          <iui-select-old
            name="signMthCd"
            :items="arrSignMthCd"
            :value="signInfo.signMthCd"
            :enable="true"
            required
            @change="onSetSignMthCd"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import pdf from 'vue-pdf';
import {mapGetters, mapMutations} from 'vuex';
export default {
  name: 'wrtcns-view',
  components: {
    pdf,
  },
  created() {
    this.addEvent({name: 'Wrtcns_Search', func: this.onSearch});
    console.log('addEvent', 'Wrtcns_Search');
    window.addEventListener('message', this.reqSetOzParam, false);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      urlParam: '',
      OZParam: {},

      arrSignMthCd: [
        {text: '선택', value: ''},
      ],

      isReject: false,
      isSign: false,

      signerInfoVo: {
        tradeNo: '',
        tradeSuffix: '',
        personId: '',
        businessNo: '',
        mphone: '',
        userNm: '',
        canSign: false,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
      },

      signInfo: {
        signSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        tradeSuffix: '',
        estmtNo: '',
        estmtOdr: 0,
        reqstdocNo: '',
        reqstdocOdr: 0,
        reqstdocDivCd: '',
        signMthCd: '',
        signerSeCd: '',
        retrnResnCn: '',
        signDt: '',
        signoneCn: '',
        signtwoCn: '',
        signthreeCn: '',
        signfourCn: '',
        signfiveCn: '',
        agentIdntyImageCn: '',
        fileNo: 0,
        accesIp: '',
        accesEnvrnCn: '',
        pkcs7SignedMessage: '',
        signerDn: '',
        vidRandom: '',
        personId: '',
        businessNo: '',
        passNo: 0,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
        eliteAgreYn: '',
        viewAgreYn: '',
        infoProvdAgreYn: '',
        tradeType:'',
        tradeName:'',
        wrtcnsNo:'',
      },

      agreYn: false,

      isVerifyPASS: false,

      pdfUrlSaved: '',
      pdfDownloader: null,

      pdfViewerConfig: {
        src: './ext/pdfjs/v2.14.305-legacy/web/viewer.html',
        url: undefined,
        viewerUrl:'',
      },
    };
  },
  mounted() {
    this.$secuKit.init();
    this.$secuKit.checkReady();
    console.log('checkReady = ' + this.$secuKit.secuKitReady);
  },
  methods: {
    ...mapMutations('WrtcnsStore', {
      setWrtcnsInfo: 'setWrtcnsInfo',
    }),
    async viewPdf() {
      const hostUrl = new URL(window.location.href);
      let origin = '';

      if (hostUrl.host.indexOf('localhost') !== -1) {
        origin = 'http://localhost:8101';
      }
      if (hostUrl.host.indexOf('127.0.0.1') !== -1) {
        origin = 'http://127.0.0.1:8101';
      }
      if (hostUrl.host.indexOf('otis-se.com') !== -1) {
        origin = 'http://econtracttest.kr.otis-se.com';
      }
      if (hostUrl.host.indexOf('ose.otis.co.kr') !== -1) {
        origin = 'https://econtracttest-ose.otis.co.kr';
      }

      console.log('hostUrl.host', hostUrl.host);

      const downloadUrl = new URL(this.wrtcnsInfo.dwldUrl);
      const url = `${origin}${downloadUrl.pathname}${downloadUrl.search}`;

      console.log('origin', origin);
      console.log('downloadUrl.pathname', downloadUrl.pathname);
      console.log('downloadUrl.search', downloadUrl.search);
      console.log('url', url);
      this.pdfViewerConfig.url = url;
    },  
    pageUp() {
      if (this.page <= 1) {
        this.page = 1;
      } else {
        this.page = this.page - 1;
      }
    },
    pageDown() {
      if (this.page >= this.numPages) {
        this.page = this.numPages;
      } else {
        this.page = this.page + 1;
      }
    },
    onSetSignMthCd(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;

      this.signInfo[target] = value;

      if (value == '30') {
        // 디지털 서명
        this.onViewReport(true, 'Y');
      } else {
        if (this.isCanSign && eval(this.signerInfoVo.customer) == true) {
          this.onViewReport(true, 'Y');
        } else {
          this.onViewReport(true, 'N');
        }
      }
    },
    onViewReport(isReload, signable) {
      // var signable    = (eval(this.signerInfoVo.canSign) == true ? 'Y' : 'N')
      //var signerSeCd = this.decParams.isBuyagnt ? '20' : eval(this.signerInfoVo.customer) == true ? '10' : '30';
      //var signerSeCd = this.signerInfoVo.buyagnt ? '20' : eval(this.signerInfoVo.customer) == true ? '10' : '30';
      var signerSeCd = this.signInfo.signerSeCd;
      var tradeNo = eval(this.signerInfoVo.canSign) == true ? this.signerInfoVo.tradeNo : '';
      var signMthCd = this.signInfo.signMthCd;

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);
      console.log('signMthCd', signMthCd);

      let param = {
        wrtcnsNo: this.wrtcnsInfo.wrtcnsNo,
        manageCd: 'WRT01',
      };

      axios
        .post('/ozrpt/selectOzQryReportFileName', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          this.OZParam.OZFormParam = {
            signable: signable, // 필수(*):서명가능여부(Y/N)
            signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
            tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
            signMthCd: signMthCd,
            draftNo: this.wrtcnsInfo.wrtcnsNo,
            checkSeq: this.wrtcnsInfo.ceckSn,
            signDt: this.wrtcnsInfo.signDt,
            signData1: this.wrtcnsInfo.signData1,
            signData2: this.wrtcnsInfo.signData2,
            tmstmpIssuDt: this.wrtcnsInfo.tmstmpIssuDt,
          };

          this.urlParam = process.env.VUE_APP_OZ_VIEWER_URL;

          if (isReload) {
            //OZ Viewer 에서 message 를 호출할 Event 등록.
            window.addEventListener('message', this.reqSetOzParam, false);

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/ false || !!document.documentMode;

            if (isIE) {
              document.getElementById('ozFrame').src = process.env.VUE_APP_OZ_VIEWER_URL;
            } else {
              //OZ Reload
              document.getElementById('ozFrame').contentWindow.location.reload(true);
            }
          }
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
      }
    },
    async downloadPdf() {
      axios.downloadPdf(this.pdfUrlSaved);
    },
    async onSearch(isReload) {
      console.log('Wrtcns_Search', 'onSearch');
      console.log('decParams.tradeNo', this.decParams.tradeNo);
      console.log('decParams.tradeSfx', this.decParams.tradeSfx);

      this.isReject = false;
      this.isSign = false;

      let param = {
        wrtcnsNo: this.decParams.wrtcnsNo,
      };

      console.log('param', param);
      if (param.wrtcnsNo === undefined || param.wrtcnsNo == '') return;

      try {
        this.listBoxLoading = true;
        let response = await axios.post('/urlLink/selectWrtcnsInfo', param);
        this.listBoxLoading = false;
        console.log(response.data);

        this.setWrtcnsInfo(response.data.wrtcnsInfo);

        if (this.isViewPdf) {
          this.viewPdf();
          this.pdfViewerConfig.viewerUrl = this.pdfViewerConfig.src+'?file=' + encodeURIComponent(this.pdfViewerConfig.url);
          this.listBoxLoading = false;
        } else {

          let param2 = {
            wrtcnsNo: this.decParams.wrtcnsNo,
            tradeNo: this.decParams.tradeNo,
            tradeSfx: this.decParams.tradeSfx,
          };
          let response2 = await axios.post('/urlLink/selectWrtcnsSignerInfo', param2);

          console.log('selectWrtcnsSignerInfo=' + JSON.stringify(response2));
          this.signerInfoVo = response2.data;

          this.signInfo.ctrtNo = '';
          this.signInfo.ctrtOdr = '';
          this.signInfo.ctrtDivCd = '';
          this.signInfo.tradeNo = this.signerInfoVo.tradeNo;
          this.signInfo.tradeSuffix = this.signerInfoVo.tradeSuffix;
          this.signInfo.canSign = this.signerInfoVo.canSign;
          this.signInfo.corporate = this.signerInfoVo.corporate;
          this.signInfo.customer = this.signerInfoVo.customer;
          this.signInfo.buyagnt = this.signerInfoVo.buyagnt;
          this.signInfo.personal = this.signerInfoVo.personal;
          this.signInfo.personId = this.signerInfoVo.personId;
          this.signInfo.businessNo = this.signerInfoVo.businessNo;
          //this.signInfo.signerSeCd = this.decParams.isBuyagnt ? '20' : '10';
          this.signInfo.signerSeCd = this.signerInfoVo.buyagnt ? '20' : '10';

          this.signInfo.tradeType = "CUST";
          this.signInfo.tradeName = this.signerInfoVo.tradeName;

          this.signInfo.wrtcnsNo = this.wrtcnsInfo.wrtcnsNo;

          this.signInfo.retrnResnCn = '';
          this.signInfo.agentIdntyImageCn = '';
          console.log("this.signInfo",this.signInfo);

          // 대리인이 아니면 공동인증서 서명이 가능.
          if (eval(this.signInfo.buyagnt) == false) {
            this.arrSignMthCd.push({text: '공동인증서', value: '10'});
          }

          // 법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능.
          if (eval(this.signInfo.corporate) == false || eval(this.signInfo.buyagnt) == true) {
            this.arrSignMthCd.push({text: '디지털서명', value: '30'});
          }

          if (this.signInfo.signMthCd == '') {
            this.onSetSignMthCd(this.arrSignMthCd[1].value, 'signMthCd');
          } else {
            this.onViewReport(isReload, 'N');
          }          
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onSign() {
      if (this.isReject) this.isReject = false;
      if (!this.isSign) this.isSign = true;

      if (!this.signInfo.signMthCd.trim()) {
        this.$alert('서명방법을 선택해야합니다.');
        return;
      }

      //if (!this.decParams.isBuyagnt && eval(this.signInfo.corporate) == true && this.signInfo.signMthCd == '30') {
        if (!this.signInfo.buyagnt && eval(this.signInfo.corporate) == true && this.signInfo.signMthCd == '30') {
        this.$alert('법인 사업자는 서명방법을 디지털서명을 선택할 수 없습니다.');
        this.signInfo.signMthCd = '';
        return;
      }

      var ozVal = '';
      if (this.signInfo.signMthCd == '10') {

        console.log('this.wrtcnsInfo.orginlHashcd', this.wrtcnsInfo.orginlHashcd);

        let checkReady = this.$secuKit.secuKitReady;
        console.log('checkReady = ' + checkReady);

        if (!checkReady) {
          this.$alert('공동인증서 툴킷 로딩이 완료되지 못했습니다.');
          return;
        }

        if (!(await this.$confirm('전자서명 하시겠습니까?'))) return;

        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: this.wrtcnsInfo.orginlHashcd,
          certID: certID,
        };

        const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
        console.log(r2);

        this.signInfo.pkcs7SignedMessage = r2.generatePKCS7SignedData;

        console.log('PKCS7SignedMessage', this.signInfo.pkcs7SignedMessage);

        let data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };
        const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
        console.log(r3);

        this.signInfo.vidRandom = r3.vidRandom;
        this.signInfo.signerDn = r3.userDN;

        console.log('vidRandom', this.signInfo.vidRandom);
        console.log('signerDn', this.signInfo.signerDn);

        let data3 = {
          certType: 'SignCert',
          sourceString: r3.vidRandom,
          certID: certID,
        };

        const r4 = await this.$secuKit.invoke('generatePKCS7SignedData', data3);
        console.log(r4);

        this.signInfo.vidRandom = r4.generatePKCS7SignedData;

        if (this.signInfo.pkcs7SignedMessage == '') {
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }

        let data4 = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(this.signInfo.pkcs7SignedMessage)),
        };

        const r5 = await this.$secuKit.verifyPKCS7SignedData(data4);
        console.log(r5);

        console.log('VerifyPKCS7SignedData', r5.verifyPKCS7SignedData);

        if (this.wrtcnsInfo.orginlHashcd != r5.verifyPKCS7SignedData) {
          this.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }
      } else if (this.signInfo.signMthCd == '20') {
        // 모바일 인증서 서명은 웹에서는 해당 없음.
      } else if (this.signInfo.signMthCd == '30') {
        // 디지털서명은 PASS 인증을 수행하고 통과 해야 디지털 서명이 가능하다.
        if (!this.isVerifyPASS) {
          this.signInfo.passNo = 0;
          console.log('this.signerInfoVo.userNm', this.signerInfoVo.userNm);
          console.log('this.signerInfoVo.mphone', this.signerInfoVo.mphone);
          var passResult = await this.$PASSCertification(this.signerInfoVo.userNm, this.signerInfoVo.mphone);
          console.log(passResult);
          if (passResult.result) {
            this.isVerifyPASS = passResult.result;
            this.signInfo.passNo = passResult.data.passNo;
          } else {
            this.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다..');
            return;
          }
        }

        //OZ Viewer 의 입력값들를 리턴받음.
        ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
        console.log('OZ Viewer input ', ozVal);
        if (ozVal == null || ozVal == '') {
          // this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
          return;
        }

        // this.signInfo.agentIdntyImageCn = ozVal[ozVal.signedFormId];
        this.signInfo.agentIdntyImageCn = ozVal[(this.signerInfoVo.buyagnt ? 'buyagntSignData' : 'signData')];

        if (this.signInfo.agentIdntyImageCn == undefined) {
          this.signInfo.agentIdntyImageCn = '';
          this.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
          return;
        }

        if (!this.signInfo.agentIdntyImageCn.trim()) {
          this.$alert('계약서의 서명란에 디지털서명을 해야합니다.');
          return;
        }
      }

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'WrtcnsView',
      };

      if (!(await this.$confirm('서명을 완료 하시겠습니까?'))) return;

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/verifyWrtcnsPKCS7SignedData', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('서명이 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('서명 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters('LinkWrtcnsStore', {
      decParams: 'decParams',
    }),
    ...mapGetters('WrtcnsStore', {
      wrtcnsInfo: 'wrtcnsInfo',
    }),
    isShowSign() {
      return this.isSign;
    },
    isCanSign() {
      return (
        eval(this.signerInfoVo.canSign) == true && this.wrtcnsInfo.wrtcnsSttusCd == '10'
      );
    },
    isOzReport() {
      return true;
    },
    isViewPdf() {
      return (
        eval(this.wrtcnsInfo.wrtcnsSttusCd == '30' || this.wrtcnsInfo.wrtcnsSttusCd == '40' || this.wrtcnsInfo.wrtcnsSttusCd == '41')
      );
    },
  },
};
</script>

<style></style>
